<template>
    <div>
        <SectionIntro />
        <SectionInformation />
        <SectionCandidates />
        <SectionReferrals />
        <SectionRecycle />
        <SectionC2A />
    </div>
</template>

<script>
import { ROUTE } from '@/enums'
import {
    SectionIntro,
    SectionInformation,
    SectionCandidates,
    SectionRecycle,
    SectionReferrals,
    SectionC2A,
} from './components'
import {
    faCheckCircle,
    faClock,
    faCircle as emptyCircle,
} from '@fortawesome/free-regular-svg-icons'
import {
    faEuroSign,
    faMapMarkerAlt,
    faCircle as fullCircle,
    faAngleDoubleDown,
} from '@fortawesome/free-solid-svg-icons'

export default {
    name: 'LandingForRecruiter',

    components: {
        SectionIntro,
        SectionInformation,
        SectionCandidates,
        SectionRecycle,
        SectionReferrals,
        SectionC2A,
    },

    data: () => ({ ROUTE }),

    computed: {
        check: () => faCheckCircle,
        euro: () => faEuroSign,
        location: () => faMapMarkerAlt,
        clock: () => faClock,
        fullCircle: () => fullCircle,
        emptyCircle: () => emptyCircle,
        arrowDown: () => faAngleDoubleDown,
    },
}
</script>

<style lang="sass" scoped>
.row
  margin: 0
h1
  font-size: 60px

.shadow
  box-shadow: 0 4px 24px 0 rgba(37, 38, 94, 0.1)
  border: 0

tr
  font-size: 18px
  color: green
  font-size: 22px
</style>
