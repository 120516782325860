



























import Vue from 'vue'
import { BaseSection } from '@/components'

export default Vue.extend({
    name: 'SectionReferrals',

    components: { BaseSection },
})
