


































import Vue from 'vue'
import { BaseSection, BaseBtn } from '@/components'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { Location } from 'vue-router'
import { ROUTE } from '@/enums'

export default Vue.extend({
    name: 'SectionIntro',

    components: { BaseSection, BaseBtn },

    data: () => ({ faArrowRight }),

    computed: {
        signInRoute(): Location {
            return { name: ROUTE.SIGN_IN }
        },
    },
})
